<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="formLoad">
          <iq-card>
            <template v-slot:body>
              <div class="container pr-5 pl-5">
              <b-row>
                <b-col lg="12" sm="12">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                      <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                        <b-row>
                          <b-col md=6>
                            <ValidationProvider name="Title" vid="title" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="title"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.title_News_en')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                  id="title"
                                  data-vv-as="Service Name"
                                  v-model="service.title"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                                                    <b-col md=6>
                            <ValidationProvider name="Title" vid="title_bn" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="title_bn"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.title_News_bn')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input
                                  id="title_bn"
                                  data-vv-as="Service Name"
                                  v-model="service.title_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col md=6>
                            <ValidationProvider name="Description (En)" vid="description" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="description"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.description_News_en')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-textarea
                                  id="description"
                                  v-model="service.description"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  rows="4"
                                  ></b-form-textarea>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col md=6>
                            <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="description_bn"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{$t('agri_portal.description_News_bn')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-textarea
                                  id="description"
                                  v-model="service.description_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  rows="4"
                                  ></b-form-textarea>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <div class="row mt-3">
                          <div class="col-md-3">
                            <p class="text-dark">{{$t('agri_portal.form_attachment')}} <span v-if="!service.id" class="text-danger">*</span></p>
                          </div>
                          <div class="col-md-9">
                            <div class="row">
                              <div class="col-md-5">
                                <ValidationProvider name="Form Attachment" vid="form_attachment" :rules="service.id ? '' : 'required'" >
                                  <b-form-group
                                    label-for="form_attachment"
                                    slot-scope="{ valid, errors }"
                                  >
                                  <b-form-file id="form_attachment"
                                  v-on:change="onFileChange"
                                  class=" text-dark" plain
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-file>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </div>
                              <div v-if='id' class="col-md-5">
                                <a :href="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + service.form_attachment" class="btn btn-sm btn-success"><i class="fa fa-download" aria-hidden="true"></i>{{ $t('agri_portal.download') }}</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                          </div>
                        </div>
                      </b-form>
                    </ValidationObserver>
                </b-col>
              </b-row>
              </div>
            </template>
          </iq-card>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { commonServiceBaseUrl } from '../../../../../config/api_config'
import { NoticeStore, NoticeUpdate } from '../../api/routes'
export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getServiceServiceData()
      this.service = tmp
      this.service.form_attachment = tmp.attachment
      this.fees = tmp.fees
      const customerType = tmp.customer_types.map(item => {
        return item.id
      })
      this.service.customer_type_id = customerType
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      previewImage: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formLoad: false,
      commonServiceBaseUrl: commonServiceBaseUrl,
      form_attachmentone: null,
      service: {
        title: '',
        description: '',
        title_bn: '',
        description_bn: '',
        form_attachment: ''
      },
      attachment: [
          {
            name: '',
            name_bn: ''
          }
        ],
      fees: [
        {
          name: '',
          name_bn: ''
        }
      ]
    }
  },
  computed: {
    componentList () {
      const listObject = this.$store.state.commonObjCommonConfig.componentList.filter(item => item.status === 0)
      return listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    portalServiceCategoryList: function () {
      return this.$store.state.commonObj.portalServiceCategoryList.filter(item => item.status === 1)
    },
    portalServiceCustomerTypeList: function () {
      return this.$store.state.commonObj.portalServiceCustomerTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
      selectImage () {
          this.$refs.fileInput.click()
      },
    onFileChange (e) {
      this.form_attachmentone = e.target.files[0]
    },
    addAttachment () {
      const tmpAtt = {
        name: '',
        name_bn: ''
      }
      this.attachment.push(tmpAtt)
    },
    removeAttachment (index) {
      this.attachment.splice(index, 1)
    },
    addFee () {
      const tmpFee = {
        name: '',
        name_bn: ''
      }
      this.fees.push(tmpFee)
    },
    removeFee (index) {
      this.fees.splice(index, 1)
    },
    getServiceServiceData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      try {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        this.formLoad = true
        const formData = new FormData()
        Object.keys(this.service).map(key => {
            if (key === 'form_attachment') {
            formData.append(key, this.form_attachmentone)
            } else {
              formData.append(key, this.service[key])
            }
        })
        // formData.append('customer_type_id', JSON.stringify(this.service.customer_type_id))
     //    formData.append('attachment', JSON.stringify(this.attachment))
        // formData.append('fees', JSON.stringify(this.fees))

         if (this.id) {
           formData.append('_method', 'PUT')
           result = await RestApi.postData(commonServiceBaseUrl, `${NoticeUpdate}/${this.id}`, formData)
         } else {
           result = await RestApi.postData(commonServiceBaseUrl, NoticeStore, formData)
         }
        this.formLoad = false
        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

         if (result.success) {
             this.$toast.success({
             title: 'Success',
             message: this.id ? 'Data updated successfully' : 'Data save successfully',
             color: '#D6E09B'
             })

             this.$bvModal.hide('modal-4')
         } else {
             this.$refs.form.setErrors(result.errors)
              this.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
         }
      } catch (error) {
          this.$toast.error({
                title: 'Error',
                message: 'something went wrong! Please, try again.'
            })
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
      }
    }
  }
</script>
<style>
  #process, #process_bn {
    height: 150px
  }
</style>
